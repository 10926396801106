<nb-layout>
  <nb-layout-column>
    <nb-card>
      <nb-card-body>

        <ng-content select="router-outlet"></ng-content>
      </nb-card-body>

    </nb-card>
  </nb-layout-column>

</nb-layout>