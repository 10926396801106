<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">Pochete.App</a>
  </div>

</div>





<div class="header-container">
  <dx-button (click)="showPop()" stylingMode="text">
    <nb-icon icon="info-outline" class="text-primary" pack="eva"></nb-icon>
  </dx-button>
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
  <nb-actions size="small">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.nome"
        [picture]="'https://avatar.iran.liara.run/public/boy?username='+user.nome">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>

<dx-popup style="position: absolute; overflow: hidden;" [width]="660" [height]="540" [showTitle]="true" title="Implementações futuras"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="popupVisible" [showCloseButton]="true">
  <div *dxTemplate="let data of 'content'">
    <div class="popup-property-details">
      <ul>
        <li>Criação e compartilhamento de contas entre usuários.<br>
          <i>Permitirá criar por exemplo uma conta chamada "Despesas do Lar", e essa conta ter as próprias categorias e
            possibilitar compartilhar a gestão dessa planilha com outra pessoa.</i>
        </li>
        <li>Implementar a opção de replicar valor para outras competências.<br>
          <i>Imagine que parcelou o seguro do carro em 6x, a planilha permitirar replicar com facilidade esse valor para os meses seguintes.</i>
        </li>
        <li>Permitir informar o status 'pago' para uma despesa, e opcionalmente informar a data de pagamento.</li>
        <li>Opção de escolher uma cor para categorias.</li>
      </ul>
      <p>Nos ajude enviando sugestões, entre em contato via whatsapp clicando <a target="_blank" href="https://wa.me/5527996029953?text=Ol%C3%A1%2C%20quero%20sugerir%20uma%20melhoria%20para%20o%20Pochete.app">aqui</a></p>

    </div>
  </div>
</dx-popup>