import { Injectable } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { UsuarioModel } from '../models/usuario.model';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(protected authService: NbAuthService ) {
    }


    getUser(){
        return JSON.parse(localStorage.getItem("usr")) as UsuarioModel
    }
}
